import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import uzh_logo from "../../images/uzh.svg"

const useStyles = makeStyles(theme => ({
  footerContainer: {
    padding: "50px",
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  footerLogo: {
    maxWidth: "150px",
    filter:
      "invert(100%) sepia(1%) saturate(0%) hue-rotate(131deg) brightness(104%) contrast(102%)",
  },
  rightCol: {
    textAlign: "right",
    "& a ": {
      color: theme.palette.common.white,
      textDecoration: "underline",
    },
  },
}))

const PageFooter = props => {
  const classes = useStyles()
  const { intl } = props

  return (
    <Grid container className={classes.footerContainer}>
      <Grid item xs={6}>
        <Typography variant="body1">
          <a href="https://www.uzh.ch">
            <img
              className={classes.footerLogo}
              src={uzh_logo}
              alt="UZH Logo"
              width="200px"
            />
          </a>
        </Typography>
        <Typography variant="body2">© Universität Zürich</Typography>
      </Grid>
      <Grid item xs={6} className={classes.rightCol}>
        <Typography variant="body1">
          <Link to="/impressum">
            {intl.formatMessage({
              id: "impressum-footer",
            })}
          </Link>
        </Typography>
        <Typography variant="body1">
          <a href="mailto:webmaster@bf.uzh.ch">
            {intl.formatMessage({
              id: "contact",
            })}
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default injectIntl(PageFooter)
