import React from "react"
import {
  IntlContextConsumer,
  changeLocale,
  injectIntl,
} from "gatsby-plugin-intl"
import { Box, MenuItem, Typography, Popover } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const languageName = {
  en: "English",
  de: "Deutsch",
}
const useStyles = makeStyles(theme => ({
  languageSwitcher: {
    fontFamily: "Nunito, Roboto, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
  },
  languageButton: {
    float: "right",
    margin: "16px 40px",
    fontFamily: "Nunito, Roboto, sans-serif",
    fontSize: "1rem",
    fontWeight: 700,
    color: theme.palette.common.black,
    textDecoration: "none",
    "& svg": {
      color: theme.palette.common.black,
      transform: "translate(0, 6px)",
    },
  },
}))

const LanguageSwitcher = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div>
      <IntlContextConsumer>
        {({ language: currentLocale }) => (
          <Box key="lang">
            <Typography
              className={classes.languageButton}
              variant="body1"
              aria-describedby={id}
              onClick={handleClick}
            >
              {languageName[currentLocale === "en" ? "en" : "de"]}{" "}
              <ExpandMoreIcon />
            </Typography>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                className={classes.languageSwitcher}
                onClick={() => changeLocale("de")}
              >
                {languageName["de"]}
              </MenuItem>
              <MenuItem
                className={classes.languageSwitcher}
                onClick={() => changeLocale("en")}
              >
                {languageName["en"]}
              </MenuItem>
            </Popover>
          </Box>
        )}
      </IntlContextConsumer>
    </div>
  )
}

export default injectIntl(LanguageSwitcher)
