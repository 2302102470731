import React from "react"
import {
  IntlContextConsumer,
  changeLocale,
  injectIntl,
} from "gatsby-plugin-intl"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const languageName = {
  en: "English",
  de: "Deutsch",
}
const useStyles = makeStyles(theme => ({}))

const LanguageSwitcherMobile = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <div>
      <IntlContextConsumer>
        {({ language: currentLocale }) => (
          <Box key="lang">
            <ul>
              <li>
                <a
                  className={classes.languageSwitcher}
                  onClick={() => changeLocale("de")}
                >
                  {languageName["de"]}
                </a>
              </li>
              <li>
                <a
                  className={classes.languageSwitcher}
                  onClick={() => changeLocale("en")}
                >
                  {languageName["en"]}
                </a>
              </li>
            </ul>
          </Box>
        )}
      </IntlContextConsumer>
    </div>
  )
}

export default injectIntl(LanguageSwitcherMobile)
