import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import LanguageSelectorMobile from "./languageSelectorMobile"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby-plugin-intl"
import clsx from "clsx"
import uzh_logo from "../../images/uzh.svg"

const useStyles = makeStyles(theme => ({
  wrapper: {
    boxSizing: "border-box",
  },
  header: {
    padding: "18px 0",
    color: theme.palette.common.black,
  },
  logoContainer: {
    height: 40,
    marginLeft: 10,
    float: "left",
    "& img": { height: "100%", width: "auto" },
  },
  menuToggle: {
    position: "fixed",
    width: 30,
    height: 30,
    transform: "rotate(0deg)",
    transition: "all 0.25s ease-in",
    cursor: "pointer",
    right: 20,
    top: 20,
    zIndex: 9999,
    "& span": {
      display: "block",
      position: "absolute",
      height: 4,
      width: "100%",
      background: theme.palette.common.black,
      borderRadius: 9,
      opacity: 1,
      left: 0,
      transform: "rotate(0deg)",
      transition: "all 0.25s ease-out",
    },
    "& span:nth-child(1)": {
      top: "10%",
      transformOrigin: "left center",
    },
    "& span:nth-child(2)": {
      top: "calc(50% - 2px)",
      left: "null",
      width: "null",
      height: "null",
      transformOrigin: "left center",
    },
    "& span:nth-child(3)": {
      top: "calc(90% - 4px)",
      transformOrigin: "left center",
      width: "null",
      opacity: 1,
    },
  },
  menuToggleOpen: {
    "& span": {
      transition: "all 0.25s ease-in",
      background: theme.palette.common.white,
    },
    "& span:nth-child(1)": { top: "calc(50% - 2px)" },
    "& span:nth-child(2)": {
      top: 0,
      left: "calc(50% - 2px)",
      width: 4,
      height: "100%",
    },
    "& span:nth-child(3)": { width: 0, opacity: 0 },
  },
  rotateContainer: {
    height: "100%",
    width: "100%",
    transition: "all 0.25s ease-in-out",
    transform: "none",
  },
  rotateContainerOpen: {
    transition: "all 0.25s ease-in-out",
    transform: "rotate(-45deg)",
  },
  menuWrapper: {
    position: "fixed",
    overflow: "hidden",
    background: "#0028A5",
    top: "-100%",
    left: 0,
    zIndex: 100,
    marginTop: 0,
    width: "100%",
    height: "100%",
    transition: "all 0.6s ease-out",
    boxShadow: "0px 4px 20px -5px #e8e8e8",
    "& ul": {
      padding: "100px 20px 0",
      textAlign: "center",
    },
    "& li": {
      listStyle: "none",
    },
    "& a": {
      color: theme.palette.common.white,
      textDecoration: "none",
      fontFamily: "Nunito, Roboto, sans-serif",
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: 1.5,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  menuWrapperOpen: { top: 0, transition: "all 0.25s ease-out" },
}))

const HeaderMobile = props => {
  const { intl } = props
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const toggleMenu = () => {
    setOpen(!open)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <Link to={"/"}>
              <img
                className={classes.logo}
                src={uzh_logo}
                alt="UZH Logo"
                width="200px"
              />
            </Link>
          </div>
          <div
            className={clsx(classes.menuToggle, {
              [classes.menuToggleOpen]: open,
            })}
            onClick={toggleMenu}
          >
            <div
              className={clsx(classes.rotateContainer, {
                [classes.rotateContainerOpen]: open,
              })}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(classes.menuWrapper, {
          [classes.menuWrapperOpen]: open,
        })}
      >
        <ul>
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/lists"}>{intl.formatMessage({ id: "lists" })}</Link>
          </li>
          <li>
            <Link to={"/categories"}>
              {intl.formatMessage({ id: "categories" })}
            </Link>
          </li>
          <li>
            <Link to={"/favorites"}>
              {intl.formatMessage({ id: "favorites" })}
            </Link>
          </li>
        </ul>
        <LanguageSelectorMobile />
      </div>
    </div>
  )
}

export default injectIntl(HeaderMobile)
