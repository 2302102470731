import { createMuiTheme } from "@material-ui/core/styles"

const bfTheme = createMuiTheme({
  palette: {
    common: {
      black: "#011126",
      blau: "#0028A5",
    },
    text: {
      primary: "#011126",
    },
    primary: {
      light: "#667EC9",
      main: "#0028A5",
      dark: "#0028A5",
    },
    secondary: {
      light: "#E38052",
      main: "#DC6027",
      dark: "#DC6027",
    },
    blau: {
      main: "#0028A5",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Nunito, Roboto, sans-serif",
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h2: {
      fontFamily: "Nunito, Roboto, sans-serif",
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h3: {
      fontFamily: "Nunito, Roboto, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontFamily: "Nunito, Roboto, sans-serif",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    button: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
})

export default bfTheme
