/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { makeStyles, ThemeProvider } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import clsx from "clsx"

import HeaderMobile from "../header/headerMobile"
import PageFooter from "../footer/footer"
import bfTheme from "../styles/theme"
import "../styles/general.css"
import { Hidden } from "@material-ui/core"
import Header from "../header/header"

const useStyles = makeStyles(theme => ({
  root: {
    background: "#fff",
  },
  footerContainer: {
    position: "relative",
    background: "white",
    zIndex: 1,
  },
  contentPane: {
    maxWidth: "1200px",
    margin: "auto",
  },
}))

const Layout = props => {
  const { children } = props
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={bfTheme}>
      <div className={classes.root}>
        <Hidden mdUp>
          <HeaderMobile siteTitle={data.site.siteMetadata?.title || `Title`} />
        </Hidden>
        <Hidden smDown>
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        </Hidden>
        <div className={clsx(classes.contentPane)}>
          <div>{children}</div>
        </div>
        <PageFooter />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
