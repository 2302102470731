import React from "react"
import clsx from "clsx"
import { injectIntl } from "gatsby-plugin-intl"
import LanguageSelector from "./languageSelector"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby-plugin-intl"
import uzh_logo from "../../images/uzh.svg"

const useStyles = makeStyles(theme => ({
  barGrid: { display: "flex" },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    margin: "20px 40px",
    marginRight: 0,
  },
  menuContainer: {},
  menuList: {
    flexGrow: 1,
    padding: 0,

    "& li": {
      display: "inline-block",
      "& a": {
        display: "block",
        fontFamily: "Nunito, Roboto, sans-serif",
        fontSize: "1rem",
        fontWeight: 700,
        color: theme.palette.common.black,
        textDecoration: "none",
        padding: "10px",
      },
    },
  },
  language: {
    flexGrow: 1,
  },
}))

const Header = props => {
  const { intl } = props
  const classes = useStyles()

  return (
    <div className={classes.barGrid}>
      <div className={classes.logoContainer}>
        <Link to={"/"}>
          <img
            className={classes.logo}
            src={uzh_logo}
            alt="UZH Logo"
            width="200px"
          />
        </Link>
      </div>
      <div className={classes.menuContainer}>
        <ul className={clsx(classes.menuList)}>
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/lists"}>{intl.formatMessage({ id: "lists" })}</Link>
          </li>
          <li>
            <Link to={"/categories"}>
              {intl.formatMessage({ id: "categories" })}
            </Link>
          </li>
          <li>
            <Link to={"/favorites"}>
              {intl.formatMessage({ id: "favorites" })}
            </Link>
          </li>
        </ul>
      </div>
      <div className={classes.language}>
        <LanguageSelector />
      </div>
    </div>
  )
}

export default injectIntl(Header)
